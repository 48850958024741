.container-skills {
  display: flex;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  background: var(--background-color);
  z-index: 2;
  position: relative;

  .icon {
    color: var(--third-color);
    font-size: 55px;
    margin-bottom: 5px;
    margin-right: 5px;
    display: block;

    &:hover {
      color: var(--secondary-color);
      transform: scale(1.1);
    }

    &:hover ~ p {
      color: var(--secondary-color);
      transform: scale(1.1);
    }
  }
}

.developer-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin: 0px 20px 0px 20px;
  text-align: center;
}

.skill {
  display: inline-block;
  margin: 12.5px 10px 12.5px 10px;

  i {
    color: var(--third-color);
  }

  i:hover {
    color: var(--secondary-color);
    transform: scale(1.1);
  }

  p {
    color: var(--third-color);
    font-size: 10px;
  }

  i:hover ~ p {
    color: var(--secondary-color);
    transform: scale(1.1);
  }
}

.divider-skills {
  border-bottom: solid 1px #777 !important;
  margin-right: 15px;

  @media screen and (max-width:766px) {
    margin-right: 0px;
  }
}
