#particles-js {
  background: var(--background-color);
  width: 100%;
  height: 100vh;
  position: absolute !important;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
}
