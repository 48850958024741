.footer {
  text-align: center;
  background: var(--background-color);
  z-index: 2;
  position: relative;

  padding-bottom: 25px;

  @media screen and (max-width:900px) {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  p {
    color: var(--third-color);

    &:hover {
      color: var(--secondary-color);
    }
  }
}
