@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap");

:root {
  --font-base: "DM Sans", sans-serif;
  --background-color: #000;
  --primary-color: #3f96ff00;
  --secondary-color: #fee715ff;
  --third-color: #a2ad99;
  --fourth-color: #9f9f9f;
  --animated-color: #fee715ff;

  --black-color: #030303;
  --lightGray-color: #e4e4e4;
  --gray-color: #6b7688;
  --brown-color: #46364a;
  --white-color: #ffffff;
}

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  scroll-behavior: smooth;
}
h1 {
  color: var(--secondary-color);
  font-size: 53px;
  margin: 0;
  font-family: "Coolvetica";
  font-weight: 400;
}
h2 {
  font-size: 1.3em;
  margin-bottom: 0.5em;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-weight: 200;
  line-height: 1em;
  color: #f2f2f2;
}
