.container-header {
  height: 100vh;
  width: 100%;
  margin: 0;
  padding: 0;
  z-index: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  animation: fadeIn 0.5s backwards;
  background-repeat: no-repeat;
  background-size: cover;
}

.home-page {
  margin: 0;
  text-align: center;

  .text-zone {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-height: 90%;
    z-index: 0;
  }

  h1 {
    color: var(--white-color);
    font-size: 53px;
    margin: 0;
    font-family: "Coolvetica";
    font-weight: 400;
  }
  h2 {
    color: var(--third-color);
    margin: 20px;
    font-style: italic;
    font-weight: 400;
    font-size: 13px;
    line-height: 1.1rem;

    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 2.5s backwards;
  }
}

.arrows {
  width: 60px;
  height: 78px;
  position: absolute;
  left: 50%;
  margin-left: -30px;
  bottom: 20px;
  z-index: 100;
  animation: fadeIn 1.8s 3.5s backwards;

  @media screen and (max-width: 767px) {
    _::-webkit-full-page-media,
    _:future,
    :root .safari_only {
      padding-bottom: 140px; //resize
    }
  }
}

.arrows path {
  stroke: var(--white-color);
  fill: transparent;
  stroke-width: 3px;
  animation: arrow 2s infinite;
  -webkit-animation: arrow 2s infinite;
}

@keyframes arrow {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0.5;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

@-webkit-keyframes arrow /*Safari and Chrome*/ {
  0% {
    opacity: 0;
  }
  40% {
    opacity: 0.5;
  }
  80% {
    opacity: 0;
  }
  100% {
    opacity: 0;
  }
}

.arrows path.a1 {
  animation-delay: -1s;
  -webkit-animation-delay: -1s; /* Safari 和 Chrome */
}

.arrows path.a2 {
  animation-delay: -0.5s;
  -webkit-animation-delay: -0.5s; /* Safari 和 Chrome */
}

.arrows path.a3 {
  animation-delay: 0s;
  -webkit-animation-delay: 0s; /* Safari 和 Chrome */
}

#icons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 15px;
  padding: 0.2rem;
  animation: fadeIn 1s 3s backwards;

  .btn {
    font-size: 35px;
    padding: 0;
    border: none;
    background: none;
    cursor: pointer;
  }
}

.linkedIn {
  color: var(--third-color);

  &:hover {
    color: var(--secondary-color);
  }
}
.github {
  color: var(--third-color);

  &:hover {
    color: var(--secondary-color);
  }
}
