.container-about {
  text-align: center;
  background: var(--background-color);

  z-index: 2;
  position: relative;
  padding-bottom: 20px;
  scroll-margin-top: 7rem;
  margin-left: 15%;
  margin-right: 15%;

  @media screen and (max-width: 400px) {
    padding-top: 30px;
  }
  @media screen and (max-width: 1300px) {
    margin-left: 0%;
    margin-right: 0%;
  }
}

.title-text {
  color: var(--third-color);
  width: 70%;
  margin: auto;
  margin-top: 20px;
  font-size: 1em;
  line-height: 1.5em;
  font-weight: 200;
  letter-spacing: 1.5px;

  @media screen and (max-width: 900px) {
    width: 90%;
    letter-spacing: 0px;
  }
}
