.container-projects {
  text-align: center;
  background: var(--background-color);
  color: var(--third-color);
  display: flex;
  z-index: 2;
  display: flex;
  justify-content: center;

  position: relative;
}

@media screen and (max-width: 380px) {
  .container-slider {
    width: 380px !important;
  }
}
@media screen and (max-width: 379px) {
  .container-slider {
    display: none !important;
  }
}

@media screen and (max-width: 768px) {
  .container-slider {
    width: 768px;
  }
}
@media screen and (max-width: 1028px) {
  .container-slider {
    width: 1028px;
  }
}
@media screen and (max-width: 1500px) {
  .container-slider {
    width: 1500px;
  }
}

.container-slider {
  width: 100%;
  height: 100%;
  display: flex;
  max-width: 1500px;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  background: var(--background-color);
  z-index: 2;
  position: relative;
  padding: 20px 20px 0px 20px;

  h1 {
    padding-bottom: 30px;
  }

  h2 {
    width: auto;
    overflow: hidden;
    margin-bottom: 0;
  }
}

.project-body {
  width: auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0px;
  background: var(--background-color);
  z-index: 2;
  position: relative;
  padding: 20px;

  h1 {
    padding-bottom: 30px;
  }
}

.swiper {
  width: 100%;
  height: 380px;
}

.swiper-wrapper {
  display: flex;
}

.swiper-slide {
  border-radius: 5px;
  text-align: center;
  font-size: 18px;
  background: var(--background-color);
  border: 2px solid var(--third-color);

  &:hover {
    border: 2px solid var(--secondary-color);
    transition: border 0.2s ease-in;
  }
}
.swiper-custom-pagination {
  margin-top: 15px;
  display: flex;
  color: var(--third-color) !important;
  justify-content: center;
  z-index: 100;
}
.swiper-pagination-bullet {
  background-color: var(--third-color) !important;
  transition: opacity 0.4s;
  --swiper-navigation-top-offset: 50px !important;
  --swiper-pagination-bullet-size: 10px;
}

.project-icons {
  display: flex;
  overflow: hidden;
  overflow-x: auto;
  justify-content: center;
  list-style: none;
  margin-top: 0px;
  margin-bottom: 0px;
  font-size: 2rem;
}

.altIcon {
  color: var(--third-color);
  font-size: 36px;
  margin-top: 15px;
  margin-left: 5px;
  margin-right: 6px;
  display: block;

  &:hover {
    color: var(--secondary-color);
    transform: scale(1.1);
  }
}

#dart {
  font-size: 31px;
  margin-top: 17px;
  margin-right: 9px;
}

.project-button {
  background-color: var(--third-color);
  border-radius: 5px;
  color: var(--background-color);
  object-fit: contain;
  font-weight: bold;
  padding: 10px 15px;
  text-align: center;
  z-index: 0;
  border: 0;
  font-size: 16px;
  cursor: pointer;
  touch-action: manipulation;
  margin-bottom: 15px;
  position: absolute;
  bottom: 0;

  @media screen and (max-width: 700px) {
    margin: 10px;
  }
}

#project-img {
  width: 100%;
  height: 54%;
  display: block;
  object-fit: contain;
}
